import { Fragment, Suspense, useEffect, useMemo, useState } from 'react'
import { Layout, Spin } from '@shopee/solar-design'
import SiderBar from './components/SiderBar'
import AppHeader from './components/Header'
import { useRoutes } from 'react-router-dom'
import routes, { IRoute } from '@/routes'
import commonConfig from '@/stores/config'
import './styles/index.less'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useCurrentRouter } from '@/hooks/router'
import NoAccessTips from '@/components/NoAccessTips'
import classNames from 'classnames'
import { tracking } from '@safety-lib/utils'
import { reportSupportHEVC } from '@/network/report'

const { Content } = Layout

function AppLayout() {
  // 使用router V6的路由表
  const element = useRoutes(routes)
  const { currentTimezone } = commonConfig
  const { getCommonConfig, soupPermission } = commonConfig
  const [browserCode, setBrowserCode] = useState(0) // 0: chrome, 1: 非chrome, 2: chrome版本低

  useEffect(() => {
    let timer
    getCommonConfig().then(() => {
      timer = setInterval(() => reportSupportHEVC(), 60000)
    })
    return () => {
      clearInterval(timer)
    }
  }, [getCommonConfig])

  useEffect(() => {
    const ua = window.navigator.userAgent
    // 如果是使用chrome，获取chrome版本
    if (ua.includes('Chrome') && !ua.includes('Edg')) {
      const bigVersion = Number(ua.match(/Chrome\/([\d.]+)/)?.[1].split('.')[0])
      if (bigVersion < 107) {
        setBrowserCode(2)
      }
    } else {
      setBrowserCode(1)
    }
  }, [browserCode])

  const currentRouters = useCurrentRouter()
  const isHiddenHeader = useMemo(() => {
    if (!currentRouters || !currentRouters.length) {
      return false
    } else if ((currentRouters[currentRouters.length - 1].route as unknown as IRoute).hiddenHeader) {
      return true
    }
    return false
  }, [currentRouters])

  const pathCls = `page${window.location.pathname.replace(/\/$/, '').replaceAll('/', '-')}`
  // 无权限
  const noAuth = useMemo(() => {
    return <NoAccessTips tipsContainer={{ btnHref: soupPermission.soupUrl, btnText: 'Soup' }} />
  }, [soupPermission.soupUrl])

  const browserCheck = useMemo(() => {
    return (
      <div className='tns-task-center-browser-check'>
        <div className='tns-task-center-browser-check-title'>Browser Compatibility Alert</div>
        <div>
          Your current browser does not meet the necessary requirements for optimal performance. To ensure smooth access
          to QC in Task Center,{' '}
          {browserCode === 1
            ? 'kindly switch to the latest version of the Chrome browser.'
            : 'please upgrade your Chrome browser to the latest version.'}
        </div>
        <div className='tns-task-center-browser-check-footer'>Thank you for your cooperation!</div>
      </div>
    )
  }, [browserCode])

  const pageContent = useMemo(() => {
    return (
      <Layout className={classnames('ant-layout-has-sider', 'tns-task-center-sider-wrapper', pathCls)}>
        <SiderBar />
        <Content className='tns-task-center-content'>
          <Suspense
            fallback={
              <div
                className={classNames('tns-task-center-content-loading', {
                  'tns-task-center-content-loading-hidden-header': isHiddenHeader
                })}
              >
                <Spin size='large' />
              </div>
            }
          >
            {element}
          </Suspense>
        </Content>
      </Layout>
    )
  }, [element, isHiddenHeader, pathCls])

  const content = useMemo(() => {
    return (
      <Fragment key={currentTimezone}>
        {isHiddenHeader ? null : <AppHeader />}
        {browserCode === 0 ? (soupPermission.status === true ? pageContent : noAuth) : browserCheck}
      </Fragment>
    )
  }, [browserCheck, browserCode, currentTimezone, isHiddenHeader, noAuth, pageContent, soupPermission.status])

  // 打点
  useEffect(() => {
    if (commonConfig.email) {
      tracking.trackView('task_center', { email: commonConfig.email })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonConfig.email])

  return (
    <Layout className='tns tns-task-center'>
      {commonConfig.isLoading && (
        <Spin size='large' spinning={commonConfig.isLoading} className='tns-task-center-loading' />
      )}
      {commonConfig.isLoading ? null : content}
    </Layout>
  )
}

export default observer(AppLayout)
