import { reportApi } from '@/network/http'
import type { ResData } from './http'
import { commonConfig } from '@/stores'
import { AuditModel } from '@/auditTpl/common/models'
import { TaskAuditMode } from '@/auditTpl/common/types'
import { Sniffer } from 'xgplayer'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export type Label = {
  key: string
  value: string
}

export type SaveType = 0 | 1 | 2

export type ReportType = 1 | 2 | 3

export interface ReportParams {
  metricName: string
  saveType?: SaveType // 1:只写日志 2:只写报表 0:都写
  reportType: ReportType // 1:耗时类型。2:个数统计 3:耗时类型可自定义Buckets（后端根据这个值调用不同的上报接口）
  buckets?: number[] // reportType为3时才有用
  labels?: Label[]
  ext?: string // 日志中存储的额外字段
  value: number // 耗时类型写入时长，单位：(ms)，个数统计写入1
}
export interface BatchReportParams {
  metricName: string
  saveType?: SaveType // 1:只写日志 2:只写报表 0:都写
  reportType: ReportType // 1:耗时类型。2:个数统计 3:耗时类型可自定义Buckets（后端根据这个值调用不同的上报接口）
  buckets?: number[] // reportType为3时才有用
  reportList: {
    labels?: Label[]
    ext?: string // 日志中存储的额外字段
    value: number // 耗时类型写入时长，单位：(ms)，个数统计写入1
  }[]
}

export const defaultBuckets = [150, 300, 550, 800, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 10000, 60000]

/**
 * 单个上报接口
 * labels不允许传值很多的字段，例如：email、taskId、queuId
 */
export const report = async (data: ReportParams): Promise<ResData<any>> => {
  if (!data.value) return { code: 0, data: null, msg: '' }
  const { email, region, businessId } = commonConfig
  const { ext: dataExt = '', labels: dataLabels = [], buckets } = data
  const ext = `${dataExt} email=${email} region=${region} businessId=${businessId}`
  const labels = [{ key: 'businessId', value: businessId + '' }, ...dataLabels]
  if (data.reportType === 3) {
    data.buckets = buckets || defaultBuckets
  }
  return await reportApi.post({
    url: '/report/add',
    data: {
      saveType: 0,
      ...data,
      labels,
      ext
    }
  })
}

/**
 * 批量上报接口
 * labels不允许传值很多的字段，例如：email、taskId、queuId
 */
export const reportBatch = async (data: BatchReportParams): Promise<ResData<any>> => {
  const { reportList, buckets } = data
  if (!reportList.length) return { code: 0, data: null, msg: '' }
  const { email, region, businessId } = commonConfig
  data.reportList = reportList.map((item) => {
    const ext = `${item.ext || ''} email=${email} region=${region} businessId=${businessId}`
    const labels = [{ key: 'businessId', value: businessId + '' }, ...(item?.labels || [])]
    return {
      labels,
      ext,
      value: item.value
    }
  })
  if (data.reportType === 3) {
    data.buckets = buckets || defaultBuckets
  }
  return await reportApi.post({
    url: `/report/batch/add_v2`,
    data: {
      saveType: 0,
      ...data
    }
  })
}

export const reportSubmitData = (data: any) => {
  report({
    metricName: 'FeSubmitData',
    labels: [{ key: '', value: '' }],
    reportType: 2,
    value: 1,
    ext: `FESubmitData data=${JSON.stringify(data)} time=${Date.now()}`
  })
}
export const reportCountAgentOnQc = (data: { mode: TaskAuditMode; auditModel: AuditModel }) => {
  const { auditModel, mode } = data
  const { queryState, taskItems, queueName, queueId } = auditModel
  const { email } = commonConfig
  if ((mode !== 'qc' && mode !== 'qa') || queryState !== 'fulfilled' || !taskItems.length) return
  report({
    metricName: 'CountQcAgent',
    labels: [
      {
        key: 'mode',
        value: mode
      },
      {
        key: 'queueName',
        value: queueName
      },
      {
        key: 'queueId',
        value: queueId + ''
      },
      {
        key: 'email',
        value: email
      }
    ],
    reportType: 2,
    ext: `CountQcAgent mode=${mode} queueName=${queueName} queueId=${queueId}`,
    value: 1
  })
}

export const reportSliceM3U8Fail = (data: {
  taskId: string | number
  queueId: string | number
  queueName: string
  url: string
  duration: number
  slice: number[]
}) => {
  const { taskId, queueId, queueName, url, duration, slice } = data
  report({
    metricName: 'SliceM3U8Error',
    reportType: 2,
    labels: [
      {
        key: 'queueId',
        value: String(queueId)
      },
      {
        key: 'queueName',
        value: queueName
      }
    ],
    ext: `SliceM3U8Error taskId=${taskId}, queueId=${queueId} url=${url} duration=${duration} slice=${slice}`,
    value: 1
  })
}

export const reportRequestTime = (data: { url?: string; ext?: string; duration?: number }) => {
  const { url, ext, duration = 0 } = data
  if (!url) return
  report({
    metricName: 'RequestTime',
    reportType: 3,
    labels: [{ key: 'url', value: url }],
    ext: `RequestTime url=${url} duration=${duration}${ext ? ' ' + ext : ''} ${window.navigator.userAgent}`,
    value: duration
  })
}

export const reportRequestFail = (data: { url?: string; code?: number | string; message?: string }) => {
  const { url, code, message } = data
  if (!url) return
  report({
    metricName: 'RequestError',
    reportType: 2,
    labels: [
      {
        key: 'url',
        value: url
      },
      {
        key: 'errorCode',
        value: String(code)
      }
    ],
    ext: `RequestError url=${url} code=${code} message='${message}'`,
    value: 1
  })
}

const isSupportHevc = String(Sniffer.isHevcSupported())

export const reportSupportHEVC = async () => {
  let deviceId = localStorage.getItem('deviceId')
  if (!deviceId) {
    const fp = await FingerprintJS.load()
    const res = await fp.get()
    deviceId = `${res.visitorId}-${Date.now()}`
    localStorage.setItem('deviceId', deviceId)
  }
  let supportHEVC = localStorage.getItem('supportHEVC')
  if (!supportHEVC || (supportHEVC === 'false' && supportHEVC !== isSupportHevc)) {
    supportHEVC = isSupportHevc
    localStorage.setItem('supportHEVC', isSupportHevc)
  }
  report({
    metricName: 'HEVCSupportCount',
    reportType: 2,
    labels: [
      {
        key: 'support',
        value: supportHEVC
      },
      {
        key: 'email',
        value: commonConfig.email
      }
    ],
    ext: `SupportHEVC browserFinger=${deviceId} supportHEVC=${supportHEVC} userAgent=${window.navigator.userAgent.replaceAll(
      ' ',
      '&'
    )}`,
    value: 1
  })
}
